import React, { useState } from "react";
import logo from "../helpers/websitelogo.png";
import { Link } from "react-router-dom";
import "./HamburgerMenu.css";

function NavBar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  function affection() {
    setMenuOpen(!isMenuOpen);
    const effectElement = document.getElementById("effect");
    if (effectElement) {
      effectElement.classList.toggle("effect");
    }
  }

  return (
    <div className="z-30">
      {/*logo and the Hamburger Menu*/}
      <div className={`pt-2 flex flex-row items-center justify-between md:container md:justify-between md:px-20 lg:mx-auto lg:px-48
       ${isMenuOpen?"":""}`}>

          <Link to={"/"}>
            <img className="h-24 object-fit" src={logo} alt="logo" />
          </Link>


        {/*Place and size of the Hamburger Menu*/}

        <div
          id="toggleMenu"
          className={`z-101 justify-center items-center flex w-20 h-20 md:hidden  ${
            isMenuOpen
              ? "fixed right-0"
              : "relative"
          }`}
          onClick={affection}
          style={{ zIndex: 1001 }}
        >
          <div id="effect"className="center z-101">
              <div></div>
            </div>
        </div> 

        <ul
          className={`fixed text-3xl gap-8 md:static md:text-base md:flex md:gap-5 ${
            isMenuOpen ? "flex flex-col justify-center items-center inset-0 pb-28" : "hidden"
          }`}
          style={{ zIndex: 1000 }}
        >
          <li className="text-white hover:text-white">
            <Link to={"/"} onClick={closeMenu}>
              Home
            </Link>
          </li>

          <li className="text-white hover:text-white ">
            <Link to="/About" onClick={closeMenu}>
              About
            </Link>
          </li>

          {/* <li className="text-white hover:text-white">
            <Link to={"/"} onClick={closeMenu}>
              Blog
            </Link>
          </li> */}
          <li className="md:hidden text-white hover:text-white">
            <Link
              target="_blank"
              to="https://github.com/gazwannagm"
              rel="noopener noreferrer"
            >
              Github
            </Link>
          </li>

          <li className="md:hidden text-white hover:text-white">
            <Link
              target="_blank"
              to="https://www.linkedin.com/in/gazwan-nagm-648576200/"
              rel="noopener noreferrer"
            >
              LinkedIn
            </Link>
          </li>

          <li className="md:hidden text-white hover:text-white">
            <Link
              target="_blank"
              to="https://twitter.com/ghazwan_nagm"
              rel="noopener noreferrer"
            >
              Twitter
            </Link>
          </li>
        </ul>
      </div>

      {isMenuOpen && (
        <div onClick={toggleMenu} className="fixed inset-0 bg-black z-40"></div>
      )}
    </div>
  );
}

export default NavBar;
