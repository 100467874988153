import React from "react";
import { Link } from "react-router-dom";
import logo from '../helpers/websitelogo.png'

function Footer() {
    return (
        <footer className="mt-24 flex justify-between md:container md:mx-auto items-center md:px-48 pb-10 flex-col lg:flex-row">
            <ul className="text-base flex gap-5 lg:pl-4 flex-col lg:flex-row text-center">

                <li className='text-neutral-300 hover:text-white'>
                    <Link to="/">Home</Link>
                </li>

                <li className='text-neutral-300 hover:text-white'>
                    <Link to="/about">About</Link>
                </li>

                {/* <li className='text-neutral-300 hover:text-white'>
                    <Link to="">Blog</Link>
                </li> */}
            </ul>

           

            <img className='h-24 object-fit order-last lg:order-none'  src={logo} alt="logo"/>

            

             {/* <ul className="flex gap-5 flex-col md:flex-row text-center" > */}
             <ul className="text-base flex gap-5 lg:pl-4 flex-col lg:flex-row text-center mt-5 lg:mt-0" >

                <li className='text-neutral-300 hover:text-white'>
                    <Link target="_blank" to ="https://github.com/gazwannagm" rel="noopener noreferrer">Github</Link>
                </li>

                <li className='text-neutral-300 hover:text-white'>
                    <Link target="_blank" to="https://www.linkedin.com/in/gazwan-nagm-648576200/" rel="noopener noreferrer">LinkedIn</Link>
                </li>

                <li className='text-neutral-300 hover:text-white'>
                    <Link target="_blank" to="https://twitter.com/ghazwan_nagm" rel="noopener noreferrer" >Twitter</Link>
                </li>

            </ul> 
            
        </footer>
    );
}

export default Footer;
