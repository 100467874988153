import React from "react";
import tsicon from "../helpers/icons8-typescript.svg"
import pyicon from "../helpers/icons8-python.svg"
import awsicon from "../helpers/icons8-amazon-web-services.svg"
import azureicon from "../helpers/icons8-azure.svg"
import Documenticon from "../helpers/icons8-curly-brackets-100.png"
import relationalDbicon from "../helpers/4474889.png"
import KeyvalueDBicon from "../helpers/icons8-key-value-database.png"
import GraphDbicon from "../helpers/nodes.png"
import DockerIcon from "../helpers/icons8-docker.svg"
import ElasticSearchIcon from "../helpers/elastic-svgrepo-com.svg"
import AlgoliaSeachIocn from "../helpers/Algolia.svg"
import k8s from "../helpers/icons8-kubernetes.svg"
import grafana from "../helpers/icons8-grafana.svg"
import openShift from "../helpers/icons8-openshift.svg"
import gpc from "../helpers/icons8-google-cloud-platform.svg"

const Experience: React.FC = () => {
    return (
      <>
        <div className='mt-24 flex flex-col left md:content-start md:container md:mx-auto md:px-52'>
            <p className='text-white text-center lg:text-left text-4xl font-bold tracking-tighter'>Modren Application Stack</p>
            <p className="text-white text-center lg:text-left text-2xl font-bold tracking-tighter my-12">Programming Languages</p> 
            <div className="flex flex-wrap justify-center lg:justify-start">
                <img className="w-[70px] mr-4 inline invert brightness-0" src="https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/csharp/csharp-line.svg" alt="CSharp"></img>
                <img className="w-[70px] mr-4 inline invert brightness-0" src={tsicon} alt="Typescrtipt"></img>
                <img className="w-[70px] inline invert brightness-0" src={pyicon} alt="Python"></img>
            </div>      
        </div>
        
        <div className='flex flex-col content-start md:container md:mx-auto md:px-52 '>
            <p className="text-white text-center lg:text-left text-2xl font-bold tracking-tighter my-12">Cloud Platforms</p> 
            <div className="flex flex-wrap justify-center lg:justify-start">
                <img className="w-[70px] mr-4 inline invert brightness-0" src={azureicon} alt="Azure"></img>
                <img className="w-[70px] mr-4 inline invert brightness-0" src={awsicon} alt="AWS"></img>
                <img className="w-[70px] inline invert brightness-0" src={gpc} alt="GPC"></img>
            </div>
        </div>

        
        <div className='flex flex-col content-start md:container md:mx-auto md:px-52 '>
            <p className="text-white text-center lg:text-left text-2xl font-bold tracking-tighter my-12">Databases</p> 
            <div className="flex flex-wrap justify-center lg:justify-start">
                <img className="md:pl-0 pl-1 w-[70px] mr-4 inline invert brightness-0" src={Documenticon} alt="DocumentDb"></img>
                <img className="w-[70px] mr-4 inline invert brightness-0" src={relationalDbicon} alt="RealtionalDb"></img>
                <img className="w-[70px] mr-4 inline invert brightness-0" src={GraphDbicon} alt="RealtionalDb"></img>
                <img className="md:pr-0 pr-1 w-[70px] inline invert brightness-0" src={KeyvalueDBicon} alt="KeyValueDb"></img>
            </div>
        </div>


        <div className='flex flex-col content-start md:container md:mx-auto md:px-52 '>
            <p className="text-white text-center lg:text-left text-2xl font-bold tracking-tighter my-12">Containerization</p> 
            <div className="flex flex-wrap justify-center lg:justify-start">
                <img className="w-[70px] inline invert brightness-0" src={DockerIcon} alt="Docker"></img>
            </div>
        </div>

        <div className='flex flex-col content-start md:container md:mx-auto md:px-52 '>
            <p className="text-white text-center lg:text-left text-2xl font-bold tracking-tighter my-12">Orchestration</p> 
            <div className="flex flex-wrap justify-center lg:justify-start">
                <img className="w-[70px] mr-4 inline invert brightness-0" src={k8s} alt="Kubernetes"></img>
                <img className="w-[70px] inline invert brightness-0" src={openShift} alt="OpenShift"></img>
            </div>
        </div>

        <div className='flex flex-col content-start md:container md:mx-auto md:px-52 '>
                <p className="text-white text-center lg:text-left text-2xl font-bold tracking-tighter my-12">Observability</p> 
                <div className="flex flex-wrap justify-center lg:justify-start">
                    <img className="w-[70px] mr-4 inline invert brightness-0" src={ElasticSearchIcon} alt="ElasticSearch"></img>
                    <img className="w-[70px] inline invert brightness-0" src={grafana} alt="Grafana"></img>
                </div> 
        </div>

        <div className='flex flex-col content-start md:container md:mx-auto md:px-52 '>
                <p className="text-white text-center lg:text-left text-2xl font-bold tracking-tighter my-12">Search Engines</p> 
                <div className="flex flex-wrap justify-center lg:justify-start">
                    <img className="w-[70px] mr-4 inline invert brightness-0" src={ElasticSearchIcon} alt="ElasticSearch"></img>
                    <img className="w-[70px] inline invert brightness-0" src={AlgoliaSeachIocn} alt="Algolia"></img>
                </div> 
        </div>
      </>
    );
}

export default Experience;