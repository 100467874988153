import React from "react";
import face from "../helpers/myface.png"


function About (){
    return(
        <>

         <div className='mt-24 flex flex-col sm:flex-row justify-between items-start lg:container lg:mx-auto lg:px-52 mx-4'>

            <div>
                
             <p className='text-white text-3xl font-bold tracking-tighter'>Job Title</p>

             <p className='text-neutral-100 mt-6 text-base '>
                  Gazwan Nagm, BackEnd Developer at CuneiCode
             </p>
             
             <p className='mt-14 text-white text-3xl font-bold tracking-tighter'>Philosophy</p>
             <p className='text-neutral-100 mt-6 text-base max-w-[500px]'>
                    "Taking a new step, uttering a new word, is what people fear most."
                    <br/> 
                    <p className="mt-4 text-right">
                        - Fyodor Dostoyevsky
                    </p>
             </p>

             <p className='mt-14 text-white text-3xl font-bold tracking-tighter' >Academic Journey</p>
             <p className='text-neutral-100 mt-6 text-base '>
                 Graduated second in my batch with a Bachelor's from the University of Technology, Baghdad.
             </p>


             <p className='mt-14 text-white text-3xl font-bold tracking-tighter'>In My Own Words</p>
             <p className='text-neutral-100 mt-6 text-base '>
             I'm Gazwan, a passionate BackEnd Developer at CuneiCode.
              My journey in tech is driven by a zest for life and a love for bringing joy to others.
               Whether it's through crafting efficient code or sharing a laugh,
                I believe in making every moment count. 
                My dedication to development is not just about writing code; 
                it's about writing chapters in people's lives, 
                ensuring each line brings a smile.
                 I thrive on hard work and continuous improvement,
                  always aiming to be better than yesterday.
             </p>

             <p className='mt-14 text-white text-3xl font-bold tracking-tighter'>The Professional Story</p>
             <p className='text-neutral-100 mt-6 text-base '>
             Gazwan, currently thriving as a BackEnd Developer at CuneiCode,
             recognized for his energetic approach and dedication to his profession.
              His work reflects a strong belief in the transformative power of technology,
               combining technical expertise with a focus on impactful outcomes. 
               Gazwan is committed to using his coding skills to develop meaningful solutions,
                emphasizing the importance of continuous learning and improvement in the backend development domain.
             </p>

            </div>

             {<img className='hidden lg:flex w-64 h-64 sm:mt-0 ' src={face} alt='Gazwan Nagm' />}
            
         </div>




        </>
    );
}

export default About