import './App.css';
import HomePage from './components/HomePage';
import NavBar from './components/NavBar';
import About from './components/About';
import Experience from './components/Expereince'; 
import Footer from './components/footer'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
      <Router>
        <NavBar />
        <Routes>
          <Route path='/' element={<HomePage/>} /> 
          <Route path='/about' element={<About/>} /> 
          <Route path='/experience' element={<Experience/>} />
          <Route path='/*' element={<HomePage/>} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default App;
