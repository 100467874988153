import React from "react";
import face from "../helpers/myface.png";
import Experience from "../components/Expereince";

function HomePage() {
  return (
    <div>
      <div
        className="px-2 mt-16 lg:mt-24 lg:h-[50vh] flex flex-col items-center justify-center lg:flex-row
             lg:justify-start lg:items-start md:container md:mx-auto md:px-20 lg:px-52"
      >
        <div className="text-center lg:text-left w-full lg:w-auto">
          <p className="text-white text-5xl lg:text-6xl xl:text-8xl font-bold tracking-tighter">
            Gazwan Nagm
          </p>
          <p className="text-neutral-400 mt-4 lg:mt-8 text-lg lg:text-xl xl:text-2xl font-bold ">
            Gazwan is a backend developer at CuneiCode, excels in creating
            robust server-side solutions. Passionate about coding and helping
            others, he specializes in database management and server architecture.
          </p>
        </div>

        <img
          className="hidden lg:block w-full lg:w-64 lg:h-64 mt-8"
          src={face}
          alt="Gazwan"
        />
      </div>
      <Experience />
    </div>
  );
}

export default HomePage;
